.requisicao{
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    font-family: 'Times New Roman', Times, serif !important;

}
.left{
    padding-top: 7vh;
    padding-left: 2rem;
    width: 20%;
}

.titulo1{
    font-size: 1.7vw;
    text-align: left;
    margin-top: 3vh;
    margin-bottom: 10vh;
    color: green;
    font-family: 'Times New Roman', Times, serif !important;
}

.right{
    width: 80%;
    padding-top: 7vh;
    padding-left: 2vw;
    padding-right: 8vw;
}
.nav-destiny{
    margin-left: 55%;
    padding-top: 1.5vh;
}
.txtND{
    font-size: 12pt;
    color: #000;
    margin-bottom: 0;
    font-family: 'Times New Roman', Times, serif !important;
}

.txtNT{
    font-size: 12pt;
    text-decoration: underline;
    font-weight: 700;
    color: #000;
    margin-top: 2vh;
    font-family: 'Times New Roman', Times, serif !important;
}

.nav-info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
}

.txtNI{
    font-size: 12pt;
    color: #000;
    margin-bottom: 0;
    font-family: 'Times New Roman', Times, serif !important;
}

.body{
    margin-top: 4vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1vh;
}

.txtBD{
    font-size: 12pt;
    color: #000;
    margin-bottom: 0;
    font-family: 'Times New Roman', Times, serif !important;
}

.itemBD{
    font-size: 12pt;
    color: #000;
    font-family: 'Times New Roman', Times, serif !important;
}

.itemBD2{
    font-size: 12pt;
    color: #000;
    margin-top: -5px;
    font-family: 'Times New Roman', Times, serif !important;
}


.container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1vw;
    margin-bottom: 4vh;
}
.container-left{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    margin: 0;
    padding: 0;
}
.container-right{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    margin: 0;
    padding: 0;
}
.container-center{

  display: flex;
  flex-direction: column;
  gap: 2vh;
  margin: 0;
  padding: 0;

}

ol, ul{
    margin-left: 0vw;
    padding-left: 1vw;
    line-height: 2;
}

.assinatura{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 5vh;
}

.txtSS{
    margin-top: 3.5vh;
    font-family: 'Times New Roman', Times, serif !important;
}

.hidd{
    display: none;
}